import React, { Component } from "react";
import EmployeeCardsGbg from "../appSpecific/EmployeeCardsGbg";
import EmployeeCardsLinkpg from "../appSpecific/EmployeeCardsLinkpg";
import AboutUsInfo from "../appSpecific/AboutUsInfo";

class AboutUsPage extends Component {
  componentDidMount() {
    this.setAboutUsActive();
  }

  setAboutUsActive = () => {
    this.props.setAboutUsActive();
  };
  render() {
    return (
      <div className="pages-container">
        <h2>Vi är Drakryggen</h2>
        <div style={{textAlign: "center"}} >I allt vi gör är vi passionerade för att bidra till en bättre framtid och strävar efter att våra arbetsuppgifter ska vara utvecklande, roliga och viktiga. 
          Vi vill åstadkomma små och stora saker som gör det bättre för samhället, vår planet och för oss själva. Vi fokuserar därför på att ge oss in i projekt som är betydelsefulla ur ett större perspektiv framför att vara kortsiktigt lönsamma. </div>
        <div className="employee-cards-wrapper-container">
          <EmployeeCardsLinkpg />
        </div>
        {/*<div className="employee-cards-wrapper-container">
          <EmployeeCardsGbg />
    </div>*/}
        <div className="header-wrapper">
          <h2 className="section-two-header">Vår historia</h2>
        </div>
        <AboutUsInfo />
      </div>
    );
  }
}

export default AboutUsPage;
